import ButtonComponent from "@components/Theme/Button/Button"
import {getLink} from "@hooks/PagesData/getLink"
import {CategoryModuleType} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import React from "react"

import styles from "./CardsCategoryButton.module.scss"

type Props = {
	link: string
	data: CategoryModuleType
	modifier: string
}

const CardsCategoryButton = (props: Props) => {
	return (
		<object>
			<ButtonComponent
				buttonType={"readMore"}
				className={styles.additionalTextLink}
				link={
					getLink(props.link) +
					`?${props.modifier}=` +
					props.data.slug +
					getLink("startOfPaginatoinM")
				}
				asLink
			>
				{props.data.title}
			</ButtonComponent>
		</object>
	)
}

export default CardsCategoryButton
